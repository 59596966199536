import { Container, Footer, Header } from "components"

const UDAAPPolicy = () => {

    const bulletPoints = [
        [
            <span>Adding service fees or other charges without notice to the Consumer. </span>,
            <span>Limiting the use of features without notice to the Consumer. </span>,
            <span>Failing to accept, process, or timely post payments or funds on Consumer accounts. </span>,
            <span>Failing to adequately secure Consumers’ nonpublic personal information.</span>
        ],
        [
            <span>Is the statement prominent enough for the Consumer to notice? </span>,
            <span>Is the information presented in an easy-to-understand format that does not contradict other 
            information in the package and at a time when the Consumer’s attention is not distracted 
            elsewhere? </span>,
            <span>Is the placement of the information in a location where Consumers can be expected to look or 
            hear? </span>,
            <span>Is the information in close proximity to the claim it qualifies?</span>
        ],
        [
            <span>• Representing a product as “no cost,” but failing to prominently disclose additional fees that would 
            be required to complete the transaction or could be incurred in connection with the relationship 
            (e.g., late fees for missed payments).</span>, 
            <span>Failing to clearly and conspicuously disclose the effect of missing payments (e.g., that the entire 
            amount outstanding will be declared immediately due and payable, and charged to the payment 
            method on file) </span>,
            <span>Representing to Consumers that they would receive the benefits of a promotional offer after 
            completing a transaction, and subsequently not providing the full benefits promised. </span>,
            <span>Orally conveying the terms of a product in a manner that is inaccurate, even where the Consumer 
            is also provided appropriate and accurate written disclosures. </span>,
            <span>Misrepresenting a company’s cybersecurity practices. </span>,
            <span>Misrepresenting any term of the transaction, failing to deliver any benefit promised, or omitting 
            any material fact in communications with Consumers.</span>
        ],
        [
            <span> lack of understanding on the part of the Consumer of the material risks, costs, or 
            conditions of the product or service; </span>,
            <span> the inability of the Consumer to protect their interests in selecting or using a 
            Consumer financial product or service; or </span>, 
            <span> the reasonable reliance by the Consumer on a covered person to act in the 
            interests of the Consumer. </span>
        ],
        [
            <span>Rushing a Consumer to authorize a transaction without allowing the Consumer time to read and 
            understand the terms of the transaction. </span>,
            <span>Taking any action that would obscure a cost or interfere with a disclosure of costs and other 
            material facts. </span>,
            <span>Making statements that may induce a reasonable Consumer to believe a company is advising and 
            acting in the best interests of the Consumer and taking unreasonable advantage of a Consumer’s 
            lack of understanding of the relationship, for example, by steering Consumers into a product that 
            may not be the most favorable for that Consumer. </span>,
            <span>Collecting debts or payments to which are not enforceable or not owed.</span>
        ]
    ]

    const circleBulletPoints = [
        [
            <span>It causes or is likely to cause substantial injury to Consumers; </span>, 
            <span>The injury is not reasonably avoidable by Consumers; and </span>,
            <span>The injury is not outweighed by countervailing benefits to Consumers or to 
            competition.</span>
        ],
        [
            <span>The representation, omission, act, or practice misleads or is likely to mislead the 
            Consumer; </span>,
            <span>The Consumer’s interpretation of the representation, omission, act, or practice is 
            reasonable under the circumstances; and </span>,
            <span>The misleading representation, omission, act, or practice is material.</span>
        ],
        [
            <span>Materially interferes with the ability of a Consumer to understand a term or condition of a 
            Consumer financial product or service; or </span>,
            <span>Takes unreasonable advantage of: </span>
        ],
        [
            <span>a lack of understanding on the part of the Consumer of the material risks, costs, or 
            conditions of the product or service; </span>,
            <span>the inability of the Consumer to protect its interests in selecting or using a Consumer 
            financial product or service; or </span>,
            <span>the reasonable reliance by the Consumer on a covered person to act in the interests of 
            the Consumer. </span>
        ],
        [
            <span>All representations are factually based. </span>,
            <span>There are no misrepresentations or omissions about key terms, such as costs, benefits, 
            conditions, or limitations. </span>,
            <span>All materials describe clearly, prominently, and accurately: </span>
        ],
        [
            <span>The materials draw the Consumer’s attention to key terms, including limitations and 
            conditions, that are important to enable the Consumer to make an informed decision. </span>,
            <span>Consumer contracts clearly inform Consumers of contract provisions that permit changes 
            in terms and conditions of the product or service. </span>,
            <span>Where a particular population is targeted, all materials clearly communicate the costs, 
            benefits, availability and other terms in language that can be understood by the target 
            population. </span>,
            <span>That the terms and benefits advertised are ones that may be actually used by targeted 
            Consumers. </span>,
            <span>The process for enrollment in the product or service creates safeguards and 
            documentation to ensure that the Consumer knowingly and voluntarily enrolled in the 
            product or service. </span>,
            <span>Products and software are providing to Consumers disclosures and information in a 
            timely manner and as intended. </span>,
            <span>Applicable staff possesses the resources needed to provide accurate information and 
            appropriate service to Consumers.</span>
        ],
        [
            <span>Whether scheduled payment captures, and any fees, exchange rates, and/or charges 
            assessed against the Consumer, are timely, prominently and accurately disclosed, and 
            accurately represented to the Consumer on an ongoing basis. </span>,
            <span>Whether the payment system structure gives Consumers control over granting payment 
            authorization. </span>,
            <span>Whether the Consumer can easily access information concerning their account, including 
            available funds, costs, rates or fees assessed, confirmation of payment, and receipt of 
            funds. </span>,
            <span>Whether payments and credits are timely posted, and the Consumer has timely and 
            convenient access to their transaction history, in order to identify unauthorized, 
            fraudulent, mistaken, or otherwise erroneous transactions. </span>,
            <span>The ease of refunding or reversing unauthorized transactions once identified, and 
            disclose the finality and irreversibility of virtual currency transactions clearly and 
            conspicuously. </span>,
            <span>Whether the Consumer is clearly and accurately disclosed the full cost of making or 
            receiving a payment, including costs resulting from an exchange rate spread, and the fees 
            or costs associated with available payment options are adequately disclosed before a 
            Consumer signs up for a service so that the Consumer can meaningfully compare options.</span>, 
            <span>Whether default payment method settings are used, whether such settings are clearly 
            and prominently disclosed, and whether the Consumer may change the default option, 
            and whether the default payment option may present additional costs, fees, or expenses 
            to the Consumer compared to other options, and how those cost, fees, or expenses are 
            disclosed to the Consumer. </span>,
            <span>Whether payments are applied in a manner that unnecessarily increases Consumer costs, 
            without clear justification. </span>,
            <span>Whether the payment system platform is broadly accessible to Consumers and 
            compatible with a wide range of qualified partners, such as various depository 
            institutions and wallet providers.</span>
        ],
        [
            <span>The benefits provided by the product or service to the Consumer and any limitations on 
            those benefits to all or certain populations; </span>,
            <span>Whether Consumers can reasonably obtain and utilize the products and services as 
            initially represented, including rewards, promotions, and other services; </span>,
            <span>Whether Consumers are placed in the specific product or service requested; </span>,
            <span>Whether actual practices are consistent with stated policies, procedures, and disclosures; 
            and </span>,
            <span>Whether there is an unusually high volume of chargebacks or refunds for a product or 
            service, and the underlying cause(s).</span>
        ]
    ]

    const bulletPointsUdaap = [
        [
            <span>Ensuring the compliance mandate established by this Policy is an integral part of the Company’s 
            operations; </span>,
            <span>Providing guidance to applicable parties to ensure that all Consumer payments are processed in 
            a timely manner; </span>,
            <span>Ensuring that Company personnel have access to resources to assist them in preventing and 
            identifying UDAAP risks; </span>,
            <span>Ensuring that an action plan is promptly executed to address identified UDAAP risks or violations 
            of law; </span>,
            <span>Ensuring that the third-party due diligence process, which may include a vendor risk assessment, 
            includes an assessment of the third party’s UDAAP compliance; </span>,
            <span>Providing guidance to ensure the Company adapts to changes mandated by law; </span>,
            <span>Providing assistance with regulatory examination results; and </span>,
            <span>Providing overall general guidance and expertise to ensure the successful implementation of this 
            Policy.</span>
        ],
        [
            <span>All Executive Management and the Board of Directors (Board) are responsible for the reasonable 
            comprehension of this Policy and for verifying that employees understand their responsibilities. </span>,
            <span>Executive Management and the Board may consult with legal counsel, as necessary or 
            appropriate. </span>,
            <span>The Company will provide access to this Policy to all current employees and the Board. </span>,
            <span>If at any time an employee has difficulty with a Consumer or is uncertain about the proper 
            method of handling a situation or transaction, such employee should refer the issue to their 
            immediate supervisor or contact the Chief Product Officer or its designee, or their delegate 
            responsible for compliance, for further clarification. </span>,
            <span>The Chief Product Officer or its designee, or their delegate, will evaluate the need for additional 
            training for staff members on issues related to this Policy. If the Chief Product Officer or its 
            designee determines that such training is to be provided, the Chief Product Officer or its designee 
            will arrange for this training and communicate to staff members when training is to take place 
            and when it is to be completed. </span>,
            <span>Additional training will be performed on an as-needed basis or as regulations change or 
            conditions warrant. </span>
        ],
        [
            <span>Prior to retention, conducting due diligence to verify that the third party understands and is 
            capable of complying with applicable law, including the prohibition on UDAAPs; </span>,
            <span>Requesting and reviewing the third party’s policies, procedures, internal controls, and training 
            materials to ensure that the provider conducts appropriate training and oversight of employees 
            or agents that have Consumer contact or compliance responsibilities; </span>,
            <span>Including in the contract with the third-party clear expectations about compliance with applicable 
            law, including the prohibition on UDAAP, as well as appropriate and enforceable consequences 
            for violating any compliance-related responsibility, up to and including termination of contract; </span>, 
            <span>Reserving the right to review and approve Consumer-facing materials to be used by the third
            party in the course of providing a service to the Company or its Consumers; </span>,
            <span>Establishing internal controls and periodic monitoring to determine that the third party is 
            complying with applicable law and not engaging in UDAAP; and </span>,
            <span>Taking prompt action to address fully any problems identified through the monitoring process, 
            including termination of the relationship where appropriate. </span>
        ],
        [
            <span> Non-compliance with this Policy;  </span>,
            <span>Encouraging, helping, or condoning non-compliance with this Policy; </span>,
            <span>Failure to report suspected UDAAP violations; or  </span>,
            <span>Violating Company policies and procedures in a manner that creates significant heightened 
            UDAAP risks, such as modifying without authorization the marketing materials or the disclosures 
            provided to Consumers. </span>
        ],
        [
            <span> Before a product or service is offered to Consumers;  </span>,
            <span> Prior to any significant modifications or changes to the terms, costs, or benefits of a product or 
            service; and  </span>,
            <span> On an ongoing basis, as long as the Company offers the product or services, as the Chief Product 
            Officer or its designee or the Responsible Person deems such reviews to be advisable or 
            necessary. </span>
        ],
        [
            <span>Marketing, Disclosures, and Enrollment: Review of (i) advertising, marketing, and promotional 
            materials in all forms of media (e.g., Internet, social media advertising, print, radio, television) 
            and (ii) initial and subsequent disclosures provided to Consumers, including Consumer 
            agreements and changes in terms. As appropriate, the review may include an assessment to 
            ensure: </span>
        ],
        [
            <span>Payment Systems and Account Servicing: As appropriate, review payment systems and Consumer 
            user platforms, including</span>
        ],
        [
            <span> Product or Service Changes: Review pricing changes or other material changes to product or 
            service terms that may affect the Consumer. Assess whether such changes are permissible under 
            the Consumer contracts, are prominently disclosed to the Consumer, and whether such changes 
            may cause injury to the Consumer, particularly with respect to increased costs that cannot be 
            avoided by the Consumer. </span>,
            <span>Availability of Terms or Services as Advertised: After a product or service has been in the market 
            for a period of time, assess whether targeted Consumers of the product or service will or have 
            received the benefits or services as advertised, disclosed, and contracted for by the Consumer. 
            As appropriate to the product or service and stage of development, the review may include an 
            analysis of: </span>
        ],
        [
            <span>Compensation Arrangements: Review compensation schemes, including incentive programs, for 
            Company employees and third-party service providers (Service Providers) retained by the 
            Company to market its or other financial services to Consumers. Particular scrutiny is appropriate 
            where there are incentive programs to employees or Service Providers that may affect the terms 
            or conditions of the product or service received by the Consumer or otherwise may create 
            unintended incentives to engage in UDAAPs. </span>,
            <span>Training Materials: As applicable to the role of an employee, review training materials concerning 
            the product or service to ensure consistency with the product programs, marketing and 
            advertising materials, and disclosures provided to Consumers concerning the product or service. </span>
        ]
    ]

    const squareBulletPoints = [
        [
            <span>Costs, benefits, and other material terms of the product or service being offered, 
            including any charges that may be imposed and the reason for the imposition;  </span>,
            <span>Related products or services being offered on an optional basis or as a 
            prerequisite to obtaining certain terms; and  </span>,
            <span>Material limitations or conditions on the terms or availability of products or 
            services, such as time limitations, promotional features, expiration dates, 
            prerequisites for obtaining particular products or services, or conditions for 
            canceling services. </span>
        ]

    ]

    const listItems = (arr: any) => arr.map(item => {
        return(
          <li style={{listStyleType: 'disc', margin: '1rem 4rem'}}>{item}</li>
        ) 
    });

    const listCircleItems = (arr: any) => arr.map(item => {
        return(
          <li style={{listStyleType: 'circle', margin: '1rem 6rem'}}>{item}</li>
        ) 
    });

    const listSquareItems = (arr: any) => arr.map(item => {
        return(
          <li style={{listStyleType: 'square', margin: '1rem 8rem'}}>{item}</li>
        ) 
    });

    return(
        <Container>
            <Header />
            <div className="privacy-policy p-1 md:p-24 ">
                <h1 className='text-4xl text-center mb-8'>UDAAP Policy</h1>
                <h4 className='text-l mb-4 font-bold'>POLICY OVERVIEW </h4>
                <p className="mb-4">
                    It is the policy of Sindric Solutions, LLC (<b>Company</b>) to comply with applicable law. Federal and state laws 
                    declare as unlawful certain unfair methods of competition, and unfair or deceptive acts or practices, in 
                    or affecting commerce. The Company does not tolerate any form of unfair, deceptive, or abusive acts or 
                    practices (<b>UDAAP</b>), in providing its products and services and is committed to complying with all 
                    applicable laws, regulations, and guidance pertaining to fair trade practices. This UDAAP Policy (<b>Policy</b>) is 
                    designed to help the Company comply with such legal requirements.
                </p>
                <p className="mb-4">
                    UDAAP can cause significant financial injury to individual consumers and other customers entitled to 
                    protection under applicable law (<b>Consumers</b>), erode Consumer confidence, create reputational risk, and 
                    undermine the financial marketplace. Entities that provide financial products or services have a legal 
                    obligation to prohibit, detect, and manage the risks of a UDAAP violation. This Policy establishes controls 
                    for the prevention, identification, assessment, and mitigation of UDAAP risk and the Company’s response 
                    to such risk. 
                </p>

                <>
                <h4 className='text-l mb-4 font-bold'>SCOPE</h4>
                <p className="mb-4">
                    This Policy applies to all of the Company’s business lines, employees, subsidiaries, affiliates, third parties, 
                    and managed resources, including, but not limited to executive officers, full-time and part-time 
                    employees, temporary and contingent workers, independent contractors, interns, and agents acting on 
                    behalf of the Company. 
                </p>
                </>

                <>
                <h4 className='text-l mb-4 font-bold'>BACKGROUND</h4>
                <p className="mb-4">
                    <ol>
                        <li style={{listStyleType: "number", marginLeft: "2rem"}} className="font-bold">
                            General
                        </li>
                        <p className="mb-4 ml-4">
                            Section 5 of the Federal Trade Commission Act (<b>FTCA</b>) passed in 1952, as amended, prohibits all persons 
                            from conducting unfair or deceptive acts and practices in or affecting commerce. In 2010, Congress passed 
                            the Dodd-Frank Wall Street Reform and Consumer Protection Act (<b>Dodd-Frank</b>), which codified a 
                            substantially similar prohibition against unfair and deceptive acts and practices, as well as a prohibition 
                            against “abusive” practices. UDAAPs can cause significant financial injury to Consumers, erode Consumer 
                            confidence, and undermine the financial marketplace. Dodd-Frank and the FTCA make it unlawful to 
                            engage in any UDAAP in the provision of financial services, and generally prohibit unfair and deceptive 
                            trade practices. State laws similarly prohibit unfair and deceptive acts and unfair trade practices in 
                            the conduct of business.
                        </p>
                        <p className="mb-4 ml-4">Title X of Dodd-Frank provides the Consumer Financial Protection Bureau (<b>CFPB</b>) with rule-making 
                            authority to ensure that Consumers are protected from UDAAP in connection with any transaction 
                            between a provider of financial products or services and a Consumer. The CFPB also has supervisory 
                            authority to detect and assess risks to Consumers and to markets for Consumer financial products and services. </p>
                        <p className="mb-4 ml-4">
                            Because Dodd-Frank’s prohibition of unfair and deceptive acts or practices mirrors the FTCA, regulators 
                            and courts have historically used the body of case law and guidance interpreting the FTCA to similarly 
                            analyze unfair and deceptive practices under Dodd-Frank. Dodd-Frank applies to “covered persons” who 
                            provide consumer financial services, and their respective service providers. The FTCA’s prohibition 
                            against unfair or deceptive acts and practices has a broader scope than Dodd-Frank and is applicable to 
                            providers of services other than consumer financial services. 
                        </p>

                        <>
                        <li style={{listStyleType: "number", marginLeft: "2rem"}} className="font-bold mb-4">
                        Key Concepts and Definitions 
                        </li>
                        <ul>
                            <li style={{listStyleType: "disc", marginLeft: "4rem"}}><b>Unfair Acts or Practices:</b> An act or practice is unfair when:</li>
                            <ul>
                                {listCircleItems(circleBulletPoints[0])}
                            </ul>

                            <li style={{listStyleType: "disc", marginLeft: "4rem"}}><b> Deceptive Acts or Practices: </b>A representation, omission, act, or practice is deceptive when: </li>   
                            <ul>
                                {listCircleItems(circleBulletPoints[1])}
                            </ul> 

                            <li style={{listStyleType: "disc", marginLeft: "4rem"}}><b> Abusive Acts or Practices: </b>An abusive act or practice:  </li>   
                            <ul>
                                {listCircleItems(circleBulletPoints[2])}
                                <ul className="ml-16">
                                    {listItems(bulletPoints[3])}
                                </ul>
                            </ul>

                            <li style={{listStyleType: "disc", marginLeft: "4rem"}} className="mb-4"><b> Consumer: </b>Under Dodd-Frank, a Consumer is an individual or an agent, trustee, or representative 
                                acting on behalf of an individual. With respect to the requirements of the FTCA, the term “Consumer” has also been interpreted to include businesses that are the “Consumers” of 
                                commercial products and services or are the targets of advertisements for such products. </li>   
                        
                        </ul>   
                        </>

                        <>
                        <li style={{listStyleType: "number", marginLeft: "2rem"}} className="font-bold">
                        Unfair Acts or Practices
                        </li>
                        <p className="mb-4 ml-4">To be “unfair”, an act or practice must cause or be likely to cause substantial injury to Consumers, which 
                        injury typically involves monetary harm. Monetary harm includes, for example, costs or fees paid by 
                        Consumers, as a result of an unfair practice. An act or practice that causes a small amount of harm, to 
                        many people, may be deemed to cause substantial injury. </p>
                        <p className="mb-4 ml-4">
                        Notwithstanding the above, actual injury is not required in every case. A significant risk of concrete harm 
                        is also sufficient. However, trivial or merely speculative harms are typically insufficient for a finding of 
                        substantial injury. Emotional impact and other more subjective types of harm also will not ordinarily 
                        amount to substantial injury. Nevertheless, in certain circumstances, such as unreasonable debt collection 
                        harassment, emotional impacts may amount to or contribute to substantial injury.
                        </p>
                        <p className="mb-4 ml-4"> 
                        An act or practice is not considered unfair if Consumers may reasonably avoid injury. Consumers cannot 
                        reasonably avoid injury if the act or practice interferes with their ability to effectively make decisions or 
                        to take action to avoid injury or if they are coerced or steered into purchasing unwanted products or 
                        services or if a transaction occurs without their knowledge or consent. 
                        </p>
                        <p className="mb-4 ml-4">
                        A key question is not whether a Consumer could have made a better choice. Rather, the question is 
                        whether an act or practice hinders a Consumer’s decision-making. For example, not having access to 
                        important information could prevent Consumers from comparing available alternatives, choosing those 
                        that are most desirable to them, and avoiding those that are inadequate or unsatisfactory. In addition, if 
                        almost all market participants engage in a practice, a Consumer’s incentive to search elsewhere for better 
                        terms is reduced, and the practice may not be reasonably avoidable. 
                        </p>
                        <p className="mb-4 ml-4">
                        The actions that a Consumer is expected to take to avoid injury must be reasonable. While a Consumer 
                        might avoid harm by hiring independent experts to test products in advance or by bringing legal claims 
                        for damages in every case of harm, these actions generally would be too expensive to be practical for 
                        individual Consumers and, therefore, are not reasonable. 
                        </p>
                        <p className="mb-4 ml-4">
                        To be unfair, the act or practice must be injurious in its net effects — that is, the injury must not be 
                        outweighed by any offsetting Consumer or competitive benefits that also are produced by the act or 
                        practice. Offsetting Consumer or competitive benefits of an act or practice may include lower prices to 
                        the Consumer or a wider availability of products and services resulting from competition. 
                        </p>
                        <p className="mb-4 ml-4">
                        Examples of unfair conduct:
                        </p>
                        <ul>
                            {listItems(bulletPoints[0])}
                        </ul>
                        </>

                        <>
                        <li style={{listStyleType: "number", marginLeft: "2rem"}} className="font-bold">
                        Deceptive Acts or Practices 
                        </li>
                        <p className="mb-4 ml-4">Deception is not limited to situations in which a Consumer has already been misled. Instead, an act or 
                        practice may be deceptive if it is <span className="italic">likely</span> to mislead Consumers. </p>
                        <p className="mb-4 ml-4">
                        It is necessary to evaluate an individual statement, representation, or omission not in isolation, but rather 
                        in the context of the entire advertisement, transaction, or course of dealing, to determine whether the 
                        overall net impression is misleading or deceptive. A representation may be an express or implied claim or 
                        promise, and it may be written or oral. If material information is necessary to prevent a Consumer from 
                        being misled, it may be deceptive to omit that information. Final compliance review of all marketing 
                        materials and advertisements is required prior to placement. 
                        </p>
                        <p className="mb-4 ml-4">
                        Written disclosures may be insufficient to correct a misleading statement or representation, particularly 
                        where the Consumer is directed away from qualifying limitations in the text or is counseled that reading 
                        the disclosures is unnecessary. Likewise, oral or fine print disclosures or contract disclosures may be 
                        insufficient to cure a misleading headline or a prominent written representation. Similarly, a deceptive 
                        act or practice may not be cured by subsequent accurate disclosures. The Company will also endeavor to 
                        test its computer programs, provide clear and accurate guidance to its Consumers and service providers, 
                        and require its service providers to represent and covenant that they will comply with applicable 
                        Consumer financial laws, including UDAAP, to ensure accurate and timely disclosures to Consumers. 
                        </p>
                        <p className="mb-4 ml-4">
                        The FTC’s “four Ps” test can assist in the evaluation of whether a representation, omission, act, or practice 
                        is likely to mislead: 
                        </p>
                        <ul>
                            {listItems(bulletPoints[1])}
                        </ul>
                        <p className="mb-4 ml-4">
                        In determining whether an act or practice is misleading, one also may consider whether the Consumer’s 
                        interpretation of or reaction to the representation, omission, act, or practice is reasonable under the 
                        circumstances. In other words, whether an act or practice is deceptive may depend on how a reasonable 
                        member of the target audience would interpret the representation. When representations or marketing 
                        practices target a specific audience, such as older Americans, young people, or financially distressed 
                        Consumers, the communication must be reviewed from the point of view of a reasonable member of that 
                        group. </p>
                        <p className="mb-4 ml-4">
                        Moreover, a representation may be deceptive if the majority of Consumers in the target class do not share 
                        the Consumer’s interpretation, so long as a significant minority of such Consumers is misled. When a 
                        seller’s representation conveys more than one meaning to reasonable Consumers, one of which is false, 
                        the seller is liable for the misleading interpretation. </p>
                        <p className="mb-4 ml-4">
                        Exaggerated claims or “puffery,” however, are not deceptive if the claims would not be taken seriously by 
                        a reasonable Consumer. </p>
                        <p className="mb-4 ml-4">
                        A representation, omission, act, or practice is material if it is likely to affect a Consumer’s choice of, or 
                        conduct regarding, the product or service. Information that is important to Consumers is material. </p>
                        <p className="mb-4 ml-4">
                        Certain categories of information are presumed to be material. In general, information about the central 
                        characteristics of a product or service – such as costs, benefits, or restrictions on the use or availability – 
                        is presumed to be material. Express claims made with respect to a financial product or service are 
                        presumed material. Implied claims are presumed to be material when evidence shows that the institution 
                        intended to make the claim (even though intent to deceive is not necessary for deception to exist). </p>
                        <p className="mb-4 ml-4">
                        Claims made with knowledge that they are false are presumed to be material. Omissions will be presumed 
                        to be material when the financial institution knew or should have known that the Consumer needed the 
                        omitted information to evaluate the product or service. </p>
                        <p className="mb-4 ml-4">
                        If a representation or claim is not presumed to be material, it still would be considered material if there 
                        is evidence that it is likely to be considered important by Consumers. </p>
                        <p className="mb-4 ml-4">
                        Examples of deceptive conduct: 
                        </p>
                        <ul>
                            {listItems(bulletPoints[2])}
                        </ul>
                        </>

                        <>
                        <li style={{listStyleType: "number", marginLeft: "2rem"}} className="font-bold mb-4">
                        Abusive Acts or Practices 
                        </li>
                        <p className="mb-4">An abusive act or practice: </p>
                        <ul>
                            <li style={{listStyleType: "disc", marginLeft: "4rem"}} className="mb-4">
                            Materially interferes with the ability of a Consumer to understand a term or condition of a 
                            Consumer financial product or service; or 
                            </li>
                            <li style={{listStyleType: "disc", marginLeft: "4rem"}} className="mb-4">
                                Takes unreasonable advantage of: 
                            </li>
                            <ul>
                                {listCircleItems(circleBulletPoints[3])}
                            </ul>
                            <p className="mb-4">Examples of abusive conduct: </p>
                            <ul>
                                {listItems(bulletPoints[4])}
                            </ul>
                        </ul>
                        </>
                    </ol>
                </p>

                <h4 className='text-l mb-4 font-bold'>UDAAP COMPLIANCE PROGRAM AND PROCEDURES</h4>
                        <li style={{listStyleType: "number"}} className="font-bold">
                        Responsibilities and Enforcement 
                        </li>
                        <p className="mb-4 ml-4 mt-4">
                        The Chief Product Officer or its designee is responsible for implementing this Policy and for determining 
                        and executing the necessary course of action for maintaining compliance with this Policy and related 
                        procedures. The Chief Product Officer or its designee are also responsible for ensuring that the Company’s 
                        UDAAP responsibilities are managed and discharged in an effective and consistent manner across the 
                        entire organization. </p>
                        <p className="mb-4 ml-4">
                        The Chief Product Officer’s or its designee’s duties include:
                        </p>
                        <ul>
                            {listItems(bulletPointsUdaap[0])}
                        </ul>

                        <li style={{listStyleType: "number"}} className="font-bold">
                        Training
                        </li>
                        <p className="mb-4 ml-4 mt-4">
                        It is the Chief Product’s or its designee’s responsibility to ensure that appropriate Company personnel 
                        and third-party service providers receive training on the directives of this Policy on an annual basis, or 
                        such other frequency deemed appropriate by the Chief Product Officer or its designee.</p>
                        <p className="mb-4 ml-4"> 
                        The Company’s general UDAAP training program consists of the following measures: 
                        </p>
                        <ul>
                            {listItems(bulletPointsUdaap[1])}
                        </ul>

                        <li style={{listStyleType: "number"}} className="font-bold">
                        Assessing Products and Services for UDAAP Risk 
                        </li>
                        <p className="mb-4 ml-4 mt-4">A designated member of the Chief Product Officer or a qualified officer or employee designated by the 
                        Chief Product Officer (Responsible Person) will manage and oversee a UDAAP assessment of the 
                        Company’s products and services at the following stages: </p>
                        <ul>
                            {listItems(bulletPoints[4])}
                        </ul>
                        <p className="mb-4 ml-4 mt-4">The Responsible Person will summarize in a written report the UDAAP assessment findings and any 
                        significant issues that should be presented to the Board or the Chief Product Officer. The Chief Product 
                        Officer is also responsible for coordinating and overseeing any necessary remediation to address 
                        identified deficiencies. </p>
                        <p className="mb-4 ml-4 mt-4">The UDAAP assessment extends throughout the lifespan of a product or service, from initial marketing 
                        through account closure. The UDAAP assessment may consider, as appropriate to the product or service 
                        and stage of development, the components listed below. No single inquiry listed below is necessarily an 
                        indication of a UDAAP risk. </p>
                        <ul>
                            {listItems(bulletPointsUdaap[5])}
                            <ul>
                                {listCircleItems(circleBulletPoints[4])}
                                <ul>
                                    {listSquareItems(squareBulletPoints[0])}
                                </ul>
                                {listCircleItems(circleBulletPoints[5])}
                            </ul>
                            {listItems(bulletPointsUdaap[6])}
                            <ul>
                                {listCircleItems(circleBulletPoints[6])}
                            </ul>
                            {listItems(bulletPointsUdaap[7])}
                            <ul>
                                {listCircleItems(circleBulletPoints[7])}
                            </ul>
                            {listItems(bulletPointsUdaap[8])}
                        </ul>



                        <li style={{listStyleType: "number"}} className="font-bold">
                        Complaint Monitoring
                        </li>
                        <p className="mb-4 ml-4 mt-4">
                        The Company will monitor complaints received from Consumers and inquiries from state and federal 
                        regulators and state Attorneys General to attempt to identify potential systemic issues that may be 
                        causing confusion, or otherwise present a risk of UDAAP claims. 
                        </p>

                        <li style={{listStyleType: "number"}} className="font-bold">
                        Third-Party Service Providers 
                        </li>
                        <p className="mb-4 ml-4 mt-4">
                        Service Providers and other third parties with whom the Company contracts to provide or market 
                        Company products or services, and who have direct and regular interaction with the Consumer or who 
                        develop or manage Consumer-facing platforms, websites, or communications, present unique UDAAP 
                        risks. The Company expects that these third parties will maintain adequate UDAAP risk controls. The 
                        Company will consider whether each such third party presents a UDAAP risk and whether it is necessary 
                        to require third parties to adopt and disclose relevant policies designed to control for that risk, prior to 
                        entering into partnerships and contracts with the Company or its financial institution partners. </p>
                        <p className="mb-4 ml-4 mt-4">
                        In order to ensure that such third parties do not present unwarranted UDAAP risk to the Consumer, the 
                        Company will consider whether it is practical or appropriate to impose certain supervisory controls. These 
                        supervisory controls may apply to any third party with whom the Company contracts, and who has direct 
                        or regular interaction with Consumers, unless the Chief Product Officer or its designee believes such third 
                        parties present a unique UDAAP risk. The supervisory controls may include, as appropriate to the level of 
                        potential risk:
                        </p>
                        <ul>
                            {listItems(bulletPointsUdaap[2])}
                        </ul>
                        <p className="mb-4 ml-4 mt-4">
                        A Responsible Person will be assigned to coordinate and oversee appropriate controls over third-party 
                        service providers, as set forth above.
                        </p>

                        <li style={{listStyleType: "number"}} className="font-bold">
                        Exceptions to Policy 
                        </li>
                        <p className="mb-4 ml-4 mt-4">Given the importance of ensuring that all Consumers are treated fairly, and that the Company does not 
                        engage in any UDAAPs, requests for exceptions to this Policy will be granted sparingly, only if there is no 
                        reasonable alternative to the exception, and solely upon the approval of the request by the Chief Product 
                        Officer or its designee. Requests for exceptions to this Policy shall be made to the Chief Product Officer or 
                        its designee, should endeavor to be as specific as possible, and discuss the availability or infeasibility of 
                        alternatives. Exceptions to this Policy may only be granted on specific items, rather than entire sections 
                        of this Policy. Company personnel with exception requests should communicate any request by 
                        submitting an internal memorandum to the Chief Product Officer or its designee for consideration. All 
                        exceptions to this Policy and the reasons therefor will be documented.</p>

                        <li style={{listStyleType: "number"}} className="font-bold">
                        Disciplinary Action
                        </li>
                        <p className="mb-4 ml-4 mt-4">In recognition of the seriousness of UDAAP concerns, the Company will impose appropriate disciplinary 
                        action against any employee who violates this Policy or otherwise fails to follow Company programs or 
                        policies and procedures in a manner that significantly heightens UDAAP risk to the Company and 
                        Consumers. Disciplinary action will be applied consistently on a fair and equitable basis to all levels of 
                        employees and may be imposed for any of the following reasons:</p>
                        <ul>
                            {listItems(bulletPointsUdaap[3])}
                        </ul>
                        <p className="mb-4 ml-4 mt-4">An employee reporting a violation of this Program or a UDAAP concern will make the report to their 
                        immediate supervisor or a member of Executive Management. If the report somehow implicates a 
                        member of Executive Management, the employee will report any potential violations to a member of 
                        Executive Management who is not implicated, or to the Board. </p>
                        <p className="mb-4 ml-4 mt-4">No employee will be punished solely on the basis that they reported what was reasonably believed to be 
                        an act of wrongdoing or a violation. However, an employee may be subject to disciplinary action if it is 
                        reasonably concluded that the report of wrongdoing was knowingly fabricated by the employee or was 
                        knowingly distorted, exaggerated, or minimized. </p>

                        <li style={{listStyleType: "number"}} className="font-bold">
                        Approval and Review of Policy
                        </li>
                        <p className="mb-4 ml-4 mt-4">
                        Executive Management has the authority to initially approve this Policy and any changes thereto. 
                        Changes, for purposes of the above, include changes to operating procedures, standards, guidelines, and 
                        technologies. 
                        </p>
                </>
            </div>
            <Footer />
        </Container>
    )
}

export default UDAAPPolicy;