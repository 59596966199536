import * as React from 'react';
import Faq from "react-faq-component";

const data = {
    rows: [
        {
            title: "What is the XDFi Protocol and whose behind it?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>The XDFi Protocol is the world’s first futures contracts protocol designed to be 100% regulatory compliant, 100% non-custodial, and 100% decentralized for all settlements. Unlike other platforms, the XDFi Protocol has zero counterparty risk for assets used to purchase futures contracts. All asset contracts are represented as an on-chain smart contract on the Flare Network and only settle utilizing the decentralized Flare Time Series Oracle (FTSO) system.  </p>
                    <p>It was designed and developed by the team at Sindric Solutions, a US based development team specializing in enterprise grade software infrastructure. </p>
                </div>
            ),
        },
        {
            title: "What is the sKYCT?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>The Sindric Know-Your-Customer Token (sKYCT) is Sindric Solutions’ first version of a compliant real-world “proof of eligibility” for users of decentralized systems. In the current form, the  sKYCT is used to establish party eligibility for the purchase of a futures contract, and then have that party matched with an equally eligible counterparty, all without revealing the identity of either party. To obtain the  sKYCT a protocol user must first submit information to a leading 3rd party KYC provider that collects the user’s information and submits the result of the process to the XDFi Protocol. Upon receipt of an eligible result, the XDFi Protocol automatically mints a non-transferrable  sKYCT on the Flare Network and deposits the token into the user’s wallet, binding the  sKYCT to the user's wallet. </p>
                    <p>The status of the  sKYCT is periodically checked to ensure the ongoing eligibility of a party, and if necessary, revoked based on the KYC providers’ result. Sindric Solutions does not provide KYC services, however the XDFi Protocol on-chain infrastructure relies on the  sKYCT for eligibility prior to allowing any deposits into on-chain contracts. </p>
                </div>
            ),
        },
        {
            title: "What happens if my sKYCT status is revoked while I have funds in the on-chain deposit contract?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>Once funds are deposited in the on-chain contract, they can only be withdrawn to the wallet containing a valid  sKYCT, and to the same wallet that originally deposited the funds. If the  sKYCT is revoked because the KYC provider returned an invalid user status (sanctions list, subpoena, etc.) the funds will remain in the deposit contract until such time as that user status is restored and a new  sKYCT minted. This ensures that users cannot receive funds while in a state of sanction. We understand this is not acceptable for all users in the marketplace but the XDFi Protocol aspires to be a home for regulatory compliance and futures positions between counterparties with known legal status. Sindric Solutions, the developer of the XDFi Protocol, never custodies any user funds and is therefore unable to unlock assets deposited on chain without a valid  sKYCT.</p>
                </div>
            )
        },  
        {
            title: "Does it cost me anything to claim my sKYCT? ",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>No, only your time! The cost of performing a KYC verification of the user via a 3rd party provider is completely covered by Sindric Solutions and does not cost the user any of their own personal funds. </p>
                </div>
            )
        },  
        {
            title: "Does the XDFi Protocol collect PII and is my information stored on-chain?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>Personally Identifiable Information (PII) is not collected by either the XDFi Protocol, nor stored on-chain on the Flare Network. PII is provided by the user to a licensed and qualified 3rd party vendor specializing in KYC/AML. This vendor maintains processes and procedures for the proper handling of all user PII and acts only as a permissive to the XDFi Protocol. Once the 3rd party KYC vendor determines eligibility for an sKYCT the response is ingested by the XDFi Protocol and the sKYCT is minted. Even the XDFi Protocol operator, Sindric Solutions, does not know the details of the PII of users using the platform, and we like it that way!</p>
                </div>
            )
        },  
        {
            title: "Can the XDFi Protocol seize, freeze, or control my assets?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>Absolutely not. The XDFi Protocol never controls any user funds in any way and operates in a 100% non-custodial manner. All fund deposits into on-chain deposit contracts are performed via user wallet transactions (which must be signed by the user) and the settlement of Long/Short positions operates via smart contracts deployed on the Flare Network. Even the price signals fed to the on-chain smart contracts are done so via the FTSO to ensure that the XDFi Protocol is immune from internal price manipulation. We’ve learned from the industry lessons of FTX, Celsius, and many others that being a custodian of user funds, freezing or controlling transactions, or manipulating price signals, undermines the confidence of both users and the broader public, in what should be fully decentralized crypto system. We aspire to the principles of Peer to Peer and “trust but verify” as core ethos. </p>
                </div>
            )
        },  
        {
            title: "How do we know the team behind the XDFi Protocol is not lying about the operating mechanics and claims being made here?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>Sindric Solutions, the developer of the XDFi Protocol, engaged with an independent 3rd party security audit agency, FYEO, to perform a threat assessment of the entire infrastructure and code base related to the protocol, including smart contracts deployed on-chain. That audit focused on cyber-security threats, vulnerabilities to both internal and external threat actors, and on-chain smart contract exploits. </p>
                    <p>All of the smart contracts deployed on-chain for deposit and settlement can also be inspected by anyone at any time without approval of Sindric Solutions. “Trust but Verify”. The only proprietary information we maintain private is our off-chain futures contract matching engines and API processes for KYC procedures. </p>
                </div>
            )
        },  
        {
            title: "How do I use the XDFi Protocol?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>We encourage you to visit our “How To” page, reach out to us on social media, and share information with each other in the community. Purchasing futures contracts on assets like Bitcoin, Ethereum, XRP, Doge, and others is simple and quick with zero counterparty risk. The Protocol automates all contract price order-book matching of Long and Short positions and on-chain smart contracts auto-settle at end-of-day. Users can even exit their positions early if there is a willing buyer. The XDFi Protocol offers both Limit and Market order types. </p>
                </div>
            )
        },  
        {
            title: "How does the XDFi Best-pricing matching algorithm protect me with Market orders?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>The Best-pricing matching algorithm will minimize the amount of purchase slippage in the contract underlying position price. If there is no one to match on the opposite position, within a specific %, the contract market order will not be executed and will be automatically cancelled. This optimizes order execution to within the % range determined by the protocol (typically 1% of the underlying asset price). </p>
                </div>
            )
        },  
        {
            title: "What are the fees associated with the XDFi Protocol?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>There are two primary fees that the user should be aware of in the user of the XDFi Protocol. </p>
                    <p>The first is the blockchain network fee (gas) required to process transactions on the Flare Network. This fee is paid in FLR token and is deducted from funds in the user wallet at the time of deposit, order submission, and withdrawal from the deposit contract. The blockchain network fee is variable and is a function primarily of the demand on the Flare Network at any given time. Historical network fee rates can be seen here: [insert link to Flare Network historical gas prices]</p>
                    <p>The second is the XDFi Protocol fee that is deducted from the deposit contract at the time of settlement. This fee is equivalent to 0.5%. A portion of this fee is provided to Sindric Solutions to operate the off-chain portions of the XDFi Protocol (sKYCT, cloud/hosting, operating expenses, etc.). Another portion of this fee is returned to owners of the XDFi Protocol token (XDFi) as a decentralized dividend (DeDiv). This portion of the fee incentives further sustainable Protocol development. </p>
                </div>
            )
        },  
        {
            title: "Does the XDFi Protocol have a token? How can I invest in the project?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>Our internal assessment concluded that an XDFi token (XDFi) useful for tracking the distribution of protocol fees back to holders would create the conditions for long term sustainability. Fostering a community of Protocol users and contributors via a decentralized dividend (DeDiv) is the most effective way to grow the Protocol and maintain that growth into the future.</p>
                </div>
            )
        },  
        {
            title: "What is the X-GT?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>The X-GT is the non-transferrable governance token used by the XDFi Protocol to grant voting power to users of the platform. X-GT is earned, minted on the Flare Network, and deposited directly in user’s wallets for use in voting on critical parameters of the XDFi Protocol. Parameters like the Tick Ratio, contract price, assets to be listed in the future, are up for consideration and voting. Neither XDFi Protocol, nor Sindric Solutions the company, earns X-GT tokens, as we want this process driven entirely by users of the protocol.  X-GT tokens also cannot be sold and they are burned at the end of each quarter to establish a level playing field for all users on an ongoing basis. </p>
                </div>
            )
        },  
        {
            title: "What is the DeDiv of the XDFi Token?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>The XDFi Token will be available in two manners: Token Distribution Events and a trading Opt-In option. The first allow users to swap other assets like FLR for XDFi tokens. The second allows traders that purchase futures contracts to settle a portion of their funds into a swap for XDFi tokens. Owners of the tokens, irrespective of how they obtain them, will earn a decentralized dividend (DeDiv) as a fraction of the Protocol fees in proportion to the total supply of XDFi tokens. These fees are transparently, periodically and programmatically distributed to users via a staking contract on the Flare Network.</p>
                </div>
            )
        },  
        {
            title: "What is the Tick Ratio?",
            content: (
                <div className='flex flex-col gap-4'>
                    <p>In traditional finance all futures contracts have a minimum price fluctuation, also known as a “tick”. Tick sizes are set by the exchange and vary by contract instrument. For the XDFi Protocol the tick is represented as a ratio between a change in underlying price and the amount of funds that are transferred between a matched Long and Short position at the time of settlement. The difference in price of the underlying asset is determined from the delta between the contract purchase price matched between Long and Short positions and the price at the time of settlement as determined by the FTSO. </p>
                    <p>Here’s an example of how the Tick Ratio applies to matched futures contract between a Long and Short order. </p>
                    <p>Assume the following, </p>
                    <p>Tick Ratio: ($1 BTC Change = 20 FLR)</p>
                    <p>Contract Purchase Price: 10,000 FLR</p>
                    <p>Bitcoin Price specified in the contract order: $100,000</p>
                    <p>Bitcoin FTSO Price (at time of end-of-day settlement): $100,250</p>
                    <p>Change in Bitcoin Price (FTSO price @ end-of-day - contract order): $100,250 - $100,000 = $250 </p>
                    <p>The price at the time of settlement was higher at end-of-day than the contract order price, therefore the Long position is favored. The Long position will receive the following:</p>
                    <p>$250 x (20 FLR / $ Change) = 5000 FLR</p>
                    <p>Final Settlement in the Deposit Contract: </p>
                    <p>Long: 15,000 FLR</p>
                    <p>Short: 5,000 FLR</p>
                    <p>Note: a protocol fee of 0.5% is applied to both the Long and the Short position so the final funds withdrawn from the deposit contract will be lower by the fee amount. </p>
                </div>
            )
        },  
    ],
};

const styles = {
    // bgColor: 'none',
    // titleTextColor: "blue",
    // rowTitleColor: "white",
    // rowContentColor: 'white',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
    expandIcon: "+",
    collapseIcon: "-",
};

export default function Faqs() {
  return (
    <Faq
        data={data}
        styles={styles}
        config={config}
    />
  );
}
