import 'styles/global.scss';


import Home from 'pages';
import FaqsPage from 'pages/faq';

import { Link, Route, Switch } from "wouter";
import TermsOfService from 'pages/toc';
import PrivacyPolicy from 'pages/privacy-policy';
import CookieConsent from "react-cookie-consent";

import ElectronicRecordsDisclosure from 'pages/electronic-records-disclosure';
import ComplaintManagementPolicy from 'pages/complaint-management-policy';
import UDAAPPolicy from 'pages/udaap-policy';

function App(): JSX.Element {
  return (
    <div className="App">
      <Switch>
        <Route path="/faqs" component={FaqsPage} />
        <Route path="/toc" component={TermsOfService} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/electronic-records-disclosure" component={ElectronicRecordsDisclosure} />
        <Route path="/complaint-management-policy" component={ComplaintManagementPolicy} />
        <Route path="/udaap-policy" component={UDAAPPolicy} />
        {/* Default route in a switch */}
        <Route>
          <Home/>
        </Route>
    </Switch>
    <CookieConsent
      buttonStyle={{ color: "#000000", backgroundColor:'#FFFFFF', borderRadius:'4px' }}
    >This website uses cookies to enhance the user experience.
    </CookieConsent>
    </div>
  );
}

export default App;
