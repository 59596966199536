import React from 'react';

import { Container, Header, Main, Footer, Cards } from 'components';
import Faqs from 'components/faqs';
import { Banner } from 'components/banner';

const Home: React.FC = () => {
  return (
    <Container>
      <Header/>
      <Banner/>
      <Main />
      <section className='mt-16 mb-4 px-5 md:px-20 py-10 flex flex-col items-center space-y-8'>
        <h2 className='text-3xl font-semibold pt-8' id='howTo'>How To</h2>

        <div className='section-gradient border border-gray-800 rounded-2xl flex flex-col md:flex-row homepage-howto-section'>
          <div className="content flex-1 flex flex-col gap-4 justify-center px-4 py-4 md:px-10 md:py-16">
            <div className='text-2xl md:text-3xl font-semibold'>Connect Your Flare Network Compatible Wallet</div>
            <div className='text-sm md:text-md font-medium'>
            The XDFi Protocol works on the decentralized Flare Network and supports most major EVM wallets. Your wallet is the source of funds for contract purchases, where you store your  sKYCT, and the location of your non-transferable voting tokens. 
            </div>
          </div>
          <div className="image-part flex-1 px-4 py-4 md:px-10 md:py-16">
            <img className='h-full object-fit object-center w-full' src={'/ht-section-1.svg'} alt='xdfi-full-logo'></img>
          </div>
        </div>
        <div className='reverse-gradient border border-gray-800 rounded-2xl flex flex-col md:flex-row-reverse homepage-howto-section'>
          <div className="content flex-1 flex flex-col gap-4 justify-center px-4 py-4 md:px-10 md:py-16">
            <div className='text-2xl md:text-3xl font-semibold'>Claim Your Sindric KYC Token ( sKYCT)</div>
            <div className='text-sm md:text-md font-medium'>
            As a compliance first protocol, the matching of Long and Short positions is achieved via the Sindric KYC Token. Once onboarded via our 3rd party provider, the protocol will mint and deposit a non-transferable  sKYCT in your wallet. The protocol will look for the  sKYCT to enable futures contract purchases, position matching, and compliance. Mint your  sKYCT today!
            </div>
          </div>
          <div className="image-part flex-1 px-4 py-4 md:px-10 md:py-16">
            <img className='h-full object-fit object-center w-full rounded-3xl' src={'/ht-section-2.svg'} alt='xdffi-full-logo'></img>
          </div>
        </div>
        <div className='section-gradient border border-gray-800 rounded-2xl flex flex-col md:flex-row homepage-howto-section'>
          <div className="content flex-1 flex flex-col gap-4 justify-center px-4 py-4 md:px-10 md:py-16">
            <div className='text-2xl md:text-3xl font-semibold'>Deposit Funds Into the Deposit Contract</div>
            <div className='text-sm md:text-md font-medium'>
            To purchase a Long or Short contract position on the XDFi Protocol you first need to deposit Funds (FLR/sFLR), into the Protocol Deposit Contract. Sign the transaction to send funds from your  wallet into the Deposit Contract. The Deposit Contract does all of the safe work, escrows matched Long and Short positions, and disburses funds at the time of settlement according to the FTSO price of the underlying asset. 100% Trustless, 100% Non-Custodial. 
            </div>
          </div>
          <div className="image-part flex-1 px-4 py-4 md:px-10 md:py-16">
            <img className='h-full object-fit object-center w-full' src={'/ht-section-3.svg'} alt='xdffi-full-logo'></img>
          </div>
        </div>
        <div className='reverse-gradient border border-gray-800 rounded-2xl flex flex-col md:flex-row-reverse homepage-howto-section'>
          <div className="content flex-1 flex flex-col gap-4 justify-center px-4 py-4 md:px-10 md:py-16">
            <div className='text-2xl md:text-3xl font-semibold'>Purchase Your First Futures Contract</div>
            <div className='text-sm md:text-md font-medium'>
            Whether hedging risk or speculating on price movement, purchasing a Futures Contract is the first step to taking a position. Use our embedded FTSO and TradingView charts to plan your move. The charts reflect the current prices. Using daily Futures Contracts, orders can be placed up to 7 days in advance, where each contract settles at 11pm EST. When you're ready, input your price target into the order book and let our best-price matching engine connect you with an eligible on-chain counterparty. We support market and limit orders. Purchase, watch, and wait for settlement!
            </div>
          </div>
          <div className="image-part flex-1 px-4 py-4 md:px-10 md:py-16">
            <img className='h-full object-cover object-center w-full' src={'/ht-section-4.svg'} alt='xdffi-full-logo'></img>
          </div>
        </div>
        <div className='section-gradient border border-gray-800 rounded-2xl flex flex-col md:flex-row homepage-howto-section'>
          <div className="content flex-1 flex flex-col gap-4 justify-center px-4 py-4 md:px-10 md:py-16">
            <div className='text-2xl md:text-3xl font-semibold'>Opt-In to recieve XDFi Tokens (XDFi)</div>
            <div className='text-xl md:text-2xl font-medium'> Coming Soon!</div>
            <div className='text-sm md:text-md font-medium'>
            Participate in the XDFi Token Distribution Event (TDE) by opting in when placing your first futures order. Choose a percentage (minimum of 20%) of your settlement funds to exchange for XDFi tokens at $0.05 per token. For example, if you opt for 20% and settle $100 equivalent, you’ll receive 2,000 XDFi tokens.
            </div>
          </div>
          <div className="image-part flex-1 px-4 py-4 md:px-10 md:py-16">
            <img className='h-full object-fit object-center w-full' src={'/ht-section-optin.svg'} alt='xdfi-full-logo'></img>
          </div>
        </div>
        <div className='reverse-gradient border border-gray-800 rounded-2xl flex flex-col md:flex-row-reverse homepage-howto-section'>
          <div className="content flex-1 flex flex-col gap-4 justify-center px-4 py-4 md:px-10 md:py-16">
            <div className='text-2xl md:text-3xl font-semibold'>Withdraw Funds at Settlement</div>
            <div className='text-sm md:text-md font-medium'>
            Monitor your position and watch the countdown counter. When the Long / Short positions settle, withdraw your funds to your wallet or use them to purchase your next contract. Earn voting power each time you buy a futures contract. 
            </div>
          </div>
          <div className="image-part flex-1 px-4 py-4 md:px-10 md:py-16">
            <img className='h-full object-cover object-center w-full' src={'/ht-section-6.svg'} alt='xdffi-full-logo'></img>
          </div>
        </div>
      </section>
      <Footer/>
    </Container>
  );
};

export default Home;
