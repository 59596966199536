import React from 'react';

import { Button } from 'components';

export const Main: React.FC = () => {
  return (
    <div className='flex flex-col w-screen mt-20'> 
      <div className='bg-one hidden md:block z-0' style={{
        backgroundImage: 'url(/bg.png)'
      }}></div>
      <div className='bg-two hidden md:block z-0' style={{
        backgroundImage: 'url(/bg.png)'
      }}></div>
      
      <div className="flex-auto flex flex-col items-center justify-center z-10">
        <div
          className="flex items-center text-sm xs:text-lg sm:text-xl md:text-3xl lg:text-4xl gap-2 md:gap-5"
        >
        Welcome to <img className='h-5 sm:h-6 md:h-14 lg:h-20' src={'/logo.svg'} alt='xdffi-full-logo'></img> Trading Protocol
        </div>
        <p className='mt-4 px-6 text-xs text-center md:text-xl italic'>
          Futures contracts settled and recorded on the Flare Network
        </p>
        <div className="mt-6 md:mt-14 flex gap-8 justify-center">
          <button
            className="border border-white p-3 px-5 rounded"
            onClick={() => {window.open("https://flare.network/xdfi-the-worlds-first-compliant-decentralized-futures-protocol/")}}
            >
            Learn More
          </button>
          <button
            className="bg-white p-3 px-5 text-black rounded hidden md:block"
            onClick={() => window.open(`https://app.flare.xdfi.io/`)}
            >
            Launch App
          </button>
        </div>
        <div className='mt-8 md:mt-14 z-0 bg-gray-900 border border-gray-800 flex flex-col w-3/4 md:w-auto md:flex-row md:p-6 md:px-8 rounded-md shadow-xl bg-gray-40'>
          <div className='flex flex-col items-center justify-center py-3 md:pb-0 border-b md:border-b-0 md:border-r md:pr-6'>
            <div className='text-2xl font-bold'>100%</div>
            <div className='font-medium'>Regulatory Compliant</div>
          </div>
          <div className='flex flex-col items-center justify-center py-3 md:pb-0 border-b md:border-b-0 md:border-r md:pr-6 md:pl-6'>
            <div className='text-2xl font-bold'>100%</div>
            <div className='font-medium'>Non-Custodial</div>
          </div>
          <div className='flex flex-col items-center justify-center py-3 md:pb-0 border-b md:border-b-0 md:border-r md:pr-6 md:pl-6'>
            <div className='text-2xl font-bold'>100%</div>
            <div className='font-medium'>Decentralised</div>
          </div>
          <div className='flex flex-col items-center justify-center py-3 md:pb-0 md:pl-6'>
            <div className='text-2xl font-bold'>Low Fees</div>
            <div className='font-medium'>Fast Transaction</div>
          </div>
        </div>
      </div>
    </div>
  );
};
