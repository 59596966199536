import { Container, Footer, Header } from "components"

const ComplaintManagementPolicy = () => {

    const bulletPoints = [
        [
            <span>Complaints are an opportunity to learn and improve the Company’s products and services. The 
            Company encourages feedback as a means of identifying and resolving root causes of 
            dissatisfaction, which may lead to a change to or improvement in the Company’s products and 
            services, increasing overall customer satisfaction. </span>,
            <span> A speedy response improves the customer experience. Complaints should be addressed and 
            resolved promptly and courteously, with common sense to improve customer satisfaction. </span>,
            <span>Complaints must be captured, recorded systemically, and tracked and followed through to 
            resolution. </span>
        ],
        [
            <span>A toll-free telephone line; </span>,
            <span>Customer service email; </span>,
            <span>An online chat feature on the website of the Company or a Partner; </span>,
            <span>A message center in a mobile application or website of the Company or a Partner; or </span>,
            <span>A physical mailing address.</span>
        ],
        [
            <span>Regulator complaint portals and other communications with regulators; </span>,
            <span>BBB website; or </span>,
            <span>Social media</span>
        ],
        [
            <span>Date the Complaint was received; </span>,
            <span>Origin of the Complaint (e.g., phone, email, regulator, BBB); </span>,
            <span>How the Complaint is categorized for purposes of tracking, escalation, and trending; </span>,
            <span>The substance of the Complaint; </span>,
            <span>The date and description of each step or action performed in addressing a Complaint, including 
            all communications, written, oral, or otherwise, between the Company and customer or third 
            party, and planned follow-up action; </span>,
            <span>How the Complaint was addressed or resolved; </span>, 
            <span>The date the Complaint was closed; and </span>,
            <span>Any additional notes or supporting documentation that may corroborate or provide color to the 
            Company’s conclusions or contribute to a root cause analysis. </span>
        ],
        [
            <span>Complaints that allege unauthorized product enrollment, account openings, or upgrades 
            (including the addition of ancillary products), improper sales practices, potential consumer harm 
            from UDAAP or discrimination, violations of law, or other regulatory compliance issues. </span>,
            <span>Complaints received from or through a regulator or other third-party. </span>,
            <span>Complaints received from an attorney or directed to or regarding a member of Executive 
            Management of the Company. </span>,
            <span>Complaints expressing dissatisfaction with the Company’s products or services or that might be 
            indicative of a failure to follow established procedures or which suggests a process deficiency that 
            might lead to a regulatory violation. </span>, 
            <span>Complaints that allege discrimination on the basis of race, ethnicity, religion, gender, gender 
            identity, sexual orientation, age, or any other prohibited basis. </span>,
            <span>Complaints that threaten escalation to the media or a regulator.</span>
        ]

    ]

    const listItems = (arr: any) => arr.map(item => {
        return(
          <li style={{listStyleType: 'disc', margin: '1rem 4rem'}}>{item}</li>
        ) 
    });

    return(
        <Container>
            <Header />
            <div className="privacy-policy p-1 md:p-24 ">
                <h1 className='text-4xl text-center mb-8'>Complaint Management Policy</h1>
                <h4 className='text-l mb-4 font-bold'>POLICY OVERVIEW </h4>
                <p className="mb-4">
                    This policy (<b>Policy</b>) is designed to ensure that Sindric Solutions, LLC (<b>Company</b>) complies with applicable 
                    laws pertaining to Complaint handling, including laws prohibiting unfair, deceptive, or abusive acts or 
                    practices (<b>UDAAP</b>), and that the Company is responsive and responsible in handling consumer 
                    Complaints and inquiries in order to deliver superior service to customers. Intelligence gathered from 
                    consumer contacts will be organized, retained, and used to enhance the Company’s products, services, 
                    and compliance with applicable law. The Company will make a deliberate, good faith effort toward 
                    resolution of each Complaint that it receives, by providing for effective risk management when dealing 
                    with Complaints, tracking Complaint resolution, and identifying enterprise issues. 
                    To the extent this Policy exceeds the requirements of applicable law and contracts, this Policy is 
                    aspirational. While it is the Company’s intention to meet the terms established in this Policy, the 
                    Company disclaims any obligation to do, perform, or act beyond what is legally required. No person may 
                    rely on this Policy, and any third-party benefit is disclaimed. 
                </p>
                <h4 className='text-l mb-4 font-bold'>SCOPE </h4>
                <p className="mb-4">
                    This Policy applies to the Company and the Company’s subsidiaries and affiliates, and their respective 
                    relationships with all third-party entities that have entered a business relationship with the Company. 
                    This Policy governs all aspects of Complaint handling, including intake, logging, investigation, resolution, 
                    analysis, and reporting. This Policy applies to any and all activity on the online platforms developed by 
                    the Company, including but not limited to the XDFi.io platform.  
                    The scope of this policy does not include whistleblower complaints, Errors (as defined in the Electronic 
                    Fund Transfer Act), Billing Errors (as defined in the Truth in Lending Act), qualified written requests (as 
                    defined in the Real Estate Settlement Procedures Act) or disputes made under the Fair Credit Reporting 
                    Act.  The Company does not currently offer any accounts, loans, or consumer reporting service subject 
                    to those statutes, but will update this policy if it does so in the future. 
                </p>
                <h4 className='text-l mb-4 font-bold'>BACKGROUND </h4>
                <p className="mb-4">
                    The Company is committed to delivering customer satisfaction and to efficiently resolving Complaints and 
                    issues brought to the attention of the Company by any customer or regulatory body. The Company 
                    recognizes that Complaints made by customers, or even employees, also create a significant risk to the 
                    Company, indicating weaknesses in the Company’s products, services, compliance with law, potentially 
                    resulting in negative attention from regulators, attorneys general, or the Better Business Bureau (BBB), 
                    as well as operational or reputational harm. To mitigate this risk, the Company is expected to and is 
                    dedicated to maintaining an effective Complaint management process.
                </p>
                <p className="mb-4">
                    Regulatory bodies, such as the Consumer Financial Protection Bureau (<b>CFPB</b>), state Attorneys General, 
                    and industry self-regulatory organizations, such as the BBB, also have prescribed procedures for resolving 
                    complaints received by those bodies and forwarded to the Company for resolution.
                </p>
                <p className="mb-4">
                    As the Company offers financial products and services in partnership with financial institutions, brand 
                    partners, or other fintech companies (each, a <b>Partner</b>), the Company may be subject to certain contractual 
                    requirements which prescribe the manner in which the Company and Partners will cooperate to resolve 
                    Complaints and share liability for a Complaint.
                </p>

                <h4 className='text-l mb-4 font-bold'>KEY CONCEPTS AND DEFINITIONS</h4>
                    <ul>
                    <li style={{listStyleType: 'disc', margin: '1rem 2rem'}}><b>Complaint:</b>A Complaint is any expression of dissatisfaction, whether oral or written, whether 
                        justified or not, in relation to the Company’s products or services, to which a response or 
                        resolution is explicitly or implicitly expected. For purposes of this Policy, an Inquiry, as defined 
                        below, is not treated as a Complaint, in general. However, if an Inquiry indicates a failure to follow 
                        established policy or procedure, a breakdown in this Policy, or suggests a potential violation of 
                        applicable law, it should also be classified as a Complaint. 
                    </li>
                    <li style={{listStyleType: 'disc', margin: '1rem 2rem'}}>
                        <b>Inquiry:</b> An inquiry is a request from a customer or other party for information or assistance 
                        regarding the Company’s products or services that does not meet the definition of a Complaint 
                        (e.g., a routine customer service request or account maintenance request). 
                    </li>
                    </ul>

                <h4 className='text-l mb-4 font-bold'>COMPLIANCE PROGRAM</h4>
                <div className="ml-4">
                <ol >
                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        General
                    </li>
                    <p className="mb-4 ml-4">
                        Complaints can range from verbal Complaints made to employees and contractors during the course of 
                        routine contact with customers to more formal, written Complaints from customers, employees, 
                        attorneys, state Attorney General offices, law enforcement officials, regulators, or the general public. 
                        Whatever the origin of the Complaint, it is essential that the Company’s Complaint handling process 
                        adhere to the following fundamental concepts:
                    </p>
                    <ul>
                        {listItems(bulletPoints[0])}
                    </ul>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        Complaint Collection
                    </li>
                    <p className="mb-4 ml-4">
                        The Company will establish communication channels through which customers may submit Complaints to 
                        the Company. At a minimum, the Company will establish the channels required by applicable law. Such 
                        channels may include, but are not limited to: 
                    </p>
                    <ul>
                        {listItems(bulletPoints[1])}
                    </ul>
                    <p className="mb-4 ml-4">
                        The Company will establish and monitor channels through which the Company may receive Complaints 
                        which are not submitted directly to the Company, including:
                    </p>
                    <ul>
                        {listItems(bulletPoints[2])}
                    </ul>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        Complaint Handling
                    </li>
                    <p className="mb-4 ml-4">
                        All Complaints received by the Company will be recorded for tracking and recordkeeping purposes. As 
                        available, the records should include: 
                    </p>
                    <ul>
                        {listItems(bulletPoints[3])}
                    </ul>
                    <p className="mb-4 ml-4">
                        All Complaints will be investigated and resolved within 30 days, unless an extension to the timeline is 
                        approved by the Chief Product Officer or its designee or applicable law or regulatory procedures provide 
                        another timeframe. Regardless of whether or not an extension is granted, the Company will maintain 
                        reasonable contact with the customer to keep the customer or regulator updated on the Company’s 
                        progress in addressing or resolving the Complaint as required or warranted by the circumstances. If 
                        additional supporting documentation is needed from the customer, the Company will promptly reach out 
                        to the customer to request such documentation within a reasonable timeframe. A failure by the customer 
                        to respond does not impact the Company’s obligations under this Policy, except as provided by applicable 
                        law. 
                    </p>
                    <p className="mb-4 ml-4">
                        The resolution of all Complaints will be communicated to the customer. If a Complaint is received from a 
                        regulator or other third-party (e.g., BBB, CFPB, state Attorney General, or a Partner), and applicable law 
                        or contract warrants a response to that third party, the Complaint will not be considered closed until such 
                        communication has been made. Similarly, if applicable law requires resolution in a particular manner (e.g., 
                        reimbursement to a consumer for an unauthorized transaction), the Complaint will not be considered 
                        closed until all such procedures have been completed. 
                    </p>
                    <p className="mb-4 ml-4">
                        Although the Company recognizes that a Complaint closure does not necessarily indicate full resolution 
                        of the underlying issue of the Complaint, a Complaint will be closed when the Company has completed 
                        and communicated its response to the customer or appropriate third party. 
                    </p>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        Escalation
                    </li>
                    <p className="mb-4 ml-4">
                        Escalated Complaints require immediate attention and will be addressed within the timeframes and in 
                        the manner required by the regulator or third-party forwarding the Complaint, as applicable. The purpose 
                        of escalating Complaints is to ensure that the Company’s Chief Product Officer or its designee is aware of 
                        Complaints or issues requiring their attention and action. The following types of Complaints should be 
                        immediately escalated to the Chief Product Officer or its designee for overseeing the resolution of the 
                        Complaint: 
                    </p>
                    <ul>
                        {listItems(bulletPoints[4])}
                    </ul>
                    <p className="mb-4 ml-4">
                        After receiving the Complaint and consulting with the appropriate individuals within the Company, 
                        including legal counsel, as necessary or appropriate, the Chief Product Officer or its supervised designee, 
                        will draft a response to the Complaint. While all Complaints should be resolved as soon as possible, under 
                        no circumstances should a response to an escalated Complaint exceed 30 days or the timeframe. If a 
                        complete resolution cannot be finalized within 20 business days, the Chief Product Officer or its 
                        supervised designee will acknowledge receipt of the Complaint and notify the customer and regulator or 
                        other third party, as applicable, that a formal response is forthcoming.
                    </p>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        Cooperation with Partners
                    </li>
                    <p className="mb-4 ml-4">
                        This section applies if the Company provides products and services in partnership with Partners. The 
                        Company’s contracts with such Partners may provide for the Company’s cooperation with the Partner in 
                        the resolution of certain Complaints, the escalation of Complaints to the Partner, or the reporting of 
                        Complaints to the Partner. The Company will comply with all such contractual obligations and implement 
                        the contractual obligations when developing Complaint management procedures for any product or 
                        service offered in partnership with a Partner. 
                    </p>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        Root Cause Analysis, Trend Reports, and Corrective Action 
                    </li>
                    <p className="mb-4 ml-4">
                        The investigation of any Complaints will include a review of the root cause of the complaint, to determine 
                        whether a systemic issue, such as a technology bug, requires remediation to prevent future similar issues. </p> 
                        <p className="mb-4 ml-4">When a root cause indicative of a systemic issue is identified, such as a technology error capable of 
                        replication, training deficiency amongst staff, compliance control failure, or a consumer-facing 
                        communication which may be causing widespread confusion, the Company will take prompt corrective 
                        action to address the root cause. Corrective action may include enhancements to the Company’s policies, procedures, or training, will be implemented, as appropriate, in response to an identified root cause or 
                        trend, in order to ensure compliance with the Company’s policies, procedures, and applicable law. Root 
                        causes not indicative of a systemic issue, such as human error, may also warrant corrective action, such 
                        as employee feedback, or a one-time customer refund. 
                        </p>
                        <p className="mb-4 ml-4">At periodic intervals determined by Executive Management, the Company will review complaints in the 
                        aggregate to determine whether any additional patterns or root cause can be identified and addressed.  
                    </p>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                    Training 
                    </li>
                    <p className="mb-4 ml-4">
                    Employees will receive training on this Policy as it pertains to their responsibilities.  
                    </p>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                    Record Retention 
                    </li>
                    <p className="mb-4 ml-4">
                        Important documentation relating to this Policy, including copies of Complaints, any corresponding 
                        investigation or analysis, Complaint responses, and Complaint reports, should be retained in compliance 
                        with law. 
                    </p>

                    <li style={{listStyleType: 'number', margin: '1rem 2rem'}} className="font-bold">
                        Approval and Review of Policy
                    </li>
                    <p className="mb-4 ml-4">
                        The Board has the authority to initially approve this Policy, and any changes thereto. Changes, for 
                        purposes of the above, include changes to operating procedures, standards, guidelines, and technologies. 
                    </p>
                </ol>
                </div>
            </div>
            <Footer />
        </Container>
    )
}

export default ComplaintManagementPolicy;
