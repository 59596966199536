import React from 'react';
import { FaSquareXTwitter, FaTelegram, FaDiscord } from 'react-icons/fa6';

export const Footer: React.FC = () => {
  return (
    <div className='flex justify-center py-1 md:py-4 px-2 md:px-16 text-xs flex-col md:flex-row w-full bottom-0 bg-black text-white border-t border-gray-500 items-center'>
      <div className="flex flex-col gap-6">
        <div className='flex flex-1 justify-center gap-x-4 gap-y-3 md:gap-8 flex-wrap mt-1 md:mt-0 w-full footer-menu-links'>
          <a href='/privacy-policy'>Privacy Policy</a>
          <a href='/toc'>Terms Of Conditions</a>
          <a href='/electronic-records-disclosure'>Electronic Records</a>
          <a href='/complaint-management-policy'>Complaint Management Policy</a>
          <a href='/udaap-policy'>UDAAP Policy</a>
          <a href='/faqs/#howTo'>FAQ</a>
          <a href='/#howTo'>How To</a>
          <div className="md:hidden w-full mx-auto border-b md:border-b-0 md:mb-0 md:pb-0 border-gray-800"></div>
        </div>
        <div className="flex flex-col gap-6">
          <div className='flex flex-1 items-center justify-center gap-8 mt-1 md:mt-0 w-full footer-menu-links'>
            <FaSquareXTwitter className='w-8 h-8 cursor-pointer' onClick={() => window.open('https://twitter.com/XDFiProtocol')}/>
            <FaTelegram className='w-8 h-8 cursor-pointer' onClick={() => window.open('https://t.me/xdfiprotocol')}/>
            <FaDiscord className='w-8 h-8 cursor-pointer' onClick={() => window.open('https://discord.gg/8nyNuSE8qt')} />
          </div>
          {/* <div className="flex gap-1 md:gap-2 items-center">
            <img className='h-4 md:h-5' src={'/sindric-logo-white.svg'} alt='sindric-logo'></img>
            <div className='flex gap-1 items-center'>© Copyright 2024 <a href='https://sindric.io'>Sindric Solutions,</a> all rights reserved.</div>
          </div> */}
        </div>
        <div className='flex flex-1 flex-col justify-between items-center gap-4 lg:gap-0 lg:flex-row text-xs w-full text-xs pb-2'>
          {/*<div className="flex gap-1 md:gap-2 cursor-pointer justify-center items-center flex-wrap" onClick={() => {window.open('https://sindric.io')}}>
            <img className='h-4' src={'/sindric-logo-white.svg'} alt='sindric-logo'></img>
            <div className='flex gap-1 items-center text-center'>© Copyright 2024 Sindric Solutions, all rights reserved.</div>
          </div>*/}
          <div className='flex flex-1 flex-row cursor-pointer justify-center items-center gap-1 md:gap-2 text-xs flex-wrap' onClick={() => {window.open('https://sindric.io')}}>
            <img className='h-4' src={'/sindric-logo-white.svg'} alt='sindric-logo'></img>
            <div className='flex gap-1 items-center text-center'>© Copyright 2025 Sindric Solutions, all rights reserved.</div>
          </div>
          <div className='flex flex-1 flex-row cursor-pointer justify-center items-center gap-1 md:gap-2 text-xs flex-wrap' onClick={() => {window.open('https://flare.network')}}>
            Running Globally, 24/7 on the <img className='h-4 md:h-5' src={'https://flare.network/wp-content/uploads/Artboard-1-1.svg'} alt='flare-logo'></img> Network
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col gap-8">
        <div className='flex flex-1 flex-col md:flex-row gap-1 md:gap-2 text-xs w-full text-xs'>
          <img className='h-4 md:h-5' src={'/sindric-logo-white.svg'} alt='sindric-logo'></img>
          <div className='flex gap-1 items-center'>© Copyright 2024 <a href='https://sindric.io'>Sindric Solutions,</a> all rights reserved.</div>
        </div>
        <div className='flex flex-1 gap-2 mt-1 md:mt-0 text-xs w-full text-xs'>
        Running Globally, 24/7 on the <img className='h-4 md:h-5' src={'https://flare.network/wp-content/uploads/Artboard-1-1.svg'} alt='flare-logo'></img> Network
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className='flex flex-1 flex-col md:flex-row gap-1 md:gap-2 w-full'>
          <FaSquareXTwitter className='w-8 h-8 cursor-pointer' onClick={() => window.open('https://twitter.com/XDFiProtocol')}/>
          <FaTelegram className='w-8 h-8 cursor-pointer' onClick={() => window.open('https://twitter.com/XDFiProtocol')}/>
        </div>
        <div className='flex flex-1 gap-2 mt-1 md:mt-0 w-full footer-menu-links'>
          <a href='' target='_blank'>Privacy Policy</a>
          <a href='' target='_blank'>Terms Of Conditions</a>
          <a href='' target='_blank'>Faqs</a>
        </div>
      </div> */}
    </div>
  );
};
